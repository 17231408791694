import React from "react";
import { Col, Row } from "react-bootstrap";

const Privacy = () => {
  const darkModeStyles = {
    container: {
      backgroundColor: "#121212",
      color: "#ffffff",
      padding: "1rem",
      borderRadius: "5px",
    },
    link: {
      color: "#ffd700",
      textDecoration: "none",
    },
    header: {
      fontSize: "23px",
      textAlign: "center",
    },
    sectionHeader: {
      fontSize: "23px",
    },
    logo: {
      filter: "invert(100%)",
    },
  };

  return (
    <div className="container my-4">
      <div className="d-flex flex-column justify-content-center align-items-center auth px-0 w-100">
        <Row className="w-100 mx-0">
          <Col lg={12} md={12} sm={12} className="mx-auto">
            <div
              className="auth-form-light text-left py-5 px-4 px-sm-5"
              style={darkModeStyles.container}
            >
              <div className="brand-logo d-flex">
                <img
                  className="mx-auto"
                  src={require("../../../../assets/images/logo.png")}
                  alt="logo"
                  // style={darkModeStyles.logo}
                />
              </div>
              <p>
                <b style={darkModeStyles.header}>
                  <center>SK8GYM App - Privacy Policy</center>
                </b>
              </p>
              <p style={darkModeStyles.sectionHeader}>
                <b>Policy</b>
              </p>
              <p>
                <b>SK8GYM</b> This Privacy Policy outlines how CenterVortex by
                Francesco Ferraro ("the Owner", "we", "us", "our") collects,
                uses, and shares information that you ("you", "your", "user")
                provide when you use the SK8GYM mobile application ("the App").
                <b>SK8GYM mobile application</b> Software.
              </p>

              <p style={darkModeStyles.sectionHeader}>
                <b>User Logs</b>
              </p>
              <p>
                1. Information We Collect
                <br />
                <br />
                When you use the App, we may collect the following information:
                a. Personal Information We may collect personal information such
                as your name, email address, phone number, and age when you
                register an account with us. We may also collect personal
                information when you use certain features of the App, such as
                messaging other users. b. Non-Personal Information We may also
                collect non-personal information, such as your device type,
                operating system, and usage data, when you use the App.
              </p>

              <p>
                2. How We Use Your Information
                <br />
                <br />
                We may use the information we collect for the following
                purposes: a. To Provide and Improve the App We may use your
                information to provide and improve the App, such as by
                customizing your experience, detecting and preventing fraud and
                abuse, and analyzing usage patterns. b. To Communicate with You
                We may use your information to communicate with you, such as by
                sending you notifications, updates, and promotional messages. c.
                To Comply with Legal Obligations We may use your information to
                comply with legal obligations, such as responding to subpoenas,
                court orders, and other legal processes.
              </p>
              <p>
                3. How We Share Your Information
                <br />
                <br />
                We may share your information with third parties in the
                following ways: a. Service Providers We may share your
                information with third-party service providers who perform
                services on our behalf, such as hosting, data analysis, and
                customer service. b. Other Users We may share your information
                with other users of the App, such as when you send messages to
                other users. c. Legal Obligations We may share your information
                to comply with legal obligations, such as responding to
                subpoenas, court orders, and other legal processes.
              </p>
              <p>
                4. Your Choices
                <br />
                <br />
                You can control your personal information by updating your
                profile or account settings. You can also choose to delete your
                account at any time.
              </p>
              <p>
                5. Security <br />
                <br />
                We take reasonable measures to protect your information from
                unauthorized access, use, and disclosure. However, no method of
                transmission over the internet or method of electronic storage
                is 100% secure.
              </p>
              <p>
                6. Children's Privacy <br />
                <br />
                The App is intended for use by individuals who are 18 years of
                age or older. However, we recognize that the App may be used by
                individuals under the age of 18, and we take steps to protect
                the privacy of such individuals. If you are under the age of 18,
                you should not provide us with any personal information without
                the consent of your parent or legal guardian. If we become aware
                that we have collected personal information from an individual
                under the age of 18 without parental consent, we will take steps
                to delete the information as soon as possible. If you are a
                parent or legal guardian and believe that your child has
                provided us with personal information, please contact us at
                <a href="mailto:info@sk8gym.com" style={darkModeStyles.link}>
                  <strong>{" "}info@sk8gym.com{" "}</strong>
                </a>
                so that we can delete the information.
              </p>
              <p>
                7. Changes to this Privacy Policy <br />
                <br />
                We may update this Privacy Policy from time to time. Any changes
                to this Privacy Policy will be posted on the App. Your continued
                use of the App after any changes to this Privacy Policy
                constitutes your acceptance of the modified Privacy Policy.
              </p>
              <p>
                8. Contact Information <br />
                <br />
                If you have any questions about this Privacy Policy, please
                contact us at
                <a
                  href="mailto:info@sk8gym.com"
                  className="XqQF9c"
                  style={darkModeStyles.link}
                >
                  <strong>{" "}info@sk8gym.com{" "}</strong>
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Privacy;
